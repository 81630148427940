const documentsFilters = [
  {
    type: "text",
    id: "customer_name__text",
    label: "Cliente",
    value: null,
    category: "a",
    operators: [""],
    countries: ["PE", "CL"],
    title: 'Información del cliente',
    placeholder: 'Escribe el nombre de la razón social'
  },
  {
    type: "text",
    id: "customer_tax_id",
    label: "RUT",
    value: null,
    category: "a",
    operators: [],
    countries: ["PE", "CL"],
    title: 'Información del cliente',
    placeholder: 'Escribe el RUT'
  },
  {
    type: "date",
    id: "date",
    label: "Fecha del documento",
    value: null,
    value2: null,
    category: "b",
    operator: 'rango',
    title: 'Fechas',
    operators: ['es igual', 'es menor a', 'es mayor a', 'rango'],
    countries: ["PE", "CL"],
    placeholder: ''
  },
  {
    type: "select",
    isMultiple: true,
    id: "type",
    value: null,
    label: "Tipo de documento",
    choices: [],
    category: "c",
    title: 'Información del documento',
    countries: ["PE", "CL"],
    placeholder: 'Selecciona el tipo de documento'
  },
  {
    type: "text",
    id: "series",
    label: "Serie",
    value: null,
    category: "c",
    countries: ["PE"],
    title: 'Información del documento',
    placeholder: 'Escribe la serie'
  },
  {
    type: "numeric",
    id: "serial",
    label: "Correlativo", // 'Número de folio',
    value: null,
    value2: null,
    category: "c",
    operator: 'rango',
    title: 'Información del documento',
    operators: ['es igual', 'es menor a', 'es mayor a', 'rango'],
    countries: ["PE", "CL"],
    appendIcon: 'mdi-pound'
  },
  {
    type: "numeric",
    id: "amount_total",
    label: "Total",
    value: null,
    value2: null,
    category: "c",
    operator: 'rango',
    operators: ["rango"],
    countries: ["PE", "CL"],
    title: 'Información del documento',
    appendIcon: 'mdi-currency-usd'
  },
  {
    type: 'text',
    id: 'order_reference',
    label: 'Orden de compra',
    value: null,
    category: 'c',
    operators: [''],
    countries: ['PE', 'CL'],
    title: 'Información del documento',
    placeholder: 'Escribe la orden de compra'
  },
  {
    type: "text",
    id: "agency_status_info__text",
    label: "Detalle del estado",
    value: null,
    category: "e",
    operators: [""],
    countries: ["PE", "CL"],
    title: 'Estado',
    placeholder: 'Escribe el detalle del estado'
  },
  {
    type: "text",
    id: "branch_id",
    label: "Tienda y caja",
    value: null,
    value2: null,
    category: "f",
    operator_branch: 'es igual',
    operator_cpos: 'es igual',
    operators_branch: ["es igual", "no tiene"],
    operators_cpos: ["es igual", "es distinto a"],
    isCombined: true,
    dependent: {
      type: "text",
      id: "cpos_id",
      label: "POS"
    },
    title: 'Tienda y POS',
    countries: ["PE", "CL"]
  }
]
export { documentsFilters }
